<template>
  <div>
    <template v-if="selectedFlag">
      <div class="cursor-pointer">
        <div class="available-action flex items-center gap-1 px-4 py-2 h-btn" @click="returnPackages()">
          <feather-icon icon="RotateCcwIcon" svgClasses="w-4 h-4" class="self-center"/>
          <span class="text-sm">{{$t('Exchange')}}</span>
        </div>
      </div>
    </template>
    <template v-else>
      <vx-tooltip position="bottom" color="warning" :text="`${$t('Please select only one delivered order to be exchanged.')}`">
        <div class="cursor-not-allowed">
          <div class="pointer-events-none font-semibold bg-cold-morning text-silver rounded flex items-center gap-1 px-4 py-2 h-btn">
            <feather-icon icon="RepeatIcon" svgClasses="w-4 h-4" class="self-center"/>
            <span class="text-sm">{{$t('Exchange')}} </span>
          </div>
        </div>
      </vx-tooltip>
    </template>
  </div>
</template>

<script>
export default {
  props: ['selected'],
  data () {
    return {
      selectedFlag: false
    }
  },
  watch: {
    selected (val) {
      if (val.length === 1 && val.length === val.filter(item => item.status === 'delivered').length) {
        this.selectedFlag = true
      } else {
        this.selectedFlag = false
      }
    }
  },
  methods: {
    returnPackages () {
      this.$emit('returnPackages')
    }
  }
}
</script>