<template>
  <div>
    <template v-if="selectedFlag">
      <div class="cursor-pointer vs-con-loading__container" id="restart-btn-loading">
        <div class="bg-primary rounded text-white text-sm font-semibold flex items-center px-4 py-2 gap-1 h-btn" @click="restartOrders()">
          <feather-icon icon="RotateCwIcon" svgClasses="w-4 h-4"></feather-icon>
          <span class="text-sm">{{$t('Recreate')}}</span>
        </div>
      </div>
    </template>
    <template v-else>
      <vx-tooltip position="bottom" color="warning" :text="`${$t('Please select canceled orders')}`">
        <div class="cursor-not-allowed">
          <div class="pointer-events-none font-semibold bg-cold-morning text-silver rounded flex items-center px-4 py-2 gap-1 h-btn">
          <feather-icon icon="RotateCwIcon" svgClasses="w-4 h-4"></feather-icon>
            <span class="text-sm">{{$t('Recreate')}}</span>
          </div>
        </div>
      </vx-tooltip>
    </template>
  </div>
</template>

<script>
import {sendRequest} from '../../../http/axios/requestHelper.js'
export default {
  props: ['selected'],
  data () {
    return {
      selectedFlag: false
    }
  },
  watch: {
    selected (val) {
      if (val.length > 0 && val.length === val.filter(item => !item.fulfillment_provider_metadata && item.status === 'cancelled').length) {
        this.selectedFlag = true
      } else {
        this.selectedFlag = false
      }
    }
  },
  methods: {
    restartOrders () {
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: '#restart-btn-loading',
        scale: 0.45
      })
      const orders = []
      this.selected.forEach(element => {
        if (element.status === 'cancelled') {
          orders.push(element.id)
        }
      })
      sendRequest(true, false, this, 'api/v1/orders/restart-cancelled/', 'post', {orders_id: orders}, true,
        () => {
          this.$emit('loadShipments')
          this.$emit('selected', [])
          this.$vs.loading.close('#restart-btn-loading > .con-vs-loading')
        }
      )
    }
  }
}
</script>