<template>
  <div>
    <shipblu-prompt
      class="shipment-modal"
      @close="closeModal"
      :active.sync="returnPackagesFlag"
      :title="$t(title)"
      :buttons-hidden="true">
      <div class="p-6">
        <div class="text-center">
          <p class="font-normal exchange-title mb-8">{{$t('In order to complete your exchange fill in the required info below')}}</p>
        </div>
        <div v-if="returnPackagesData.fulfillment_provider_metadata">
          <div class="text-center mt-10">
            <p class="font-medium leading-5 text-base sub-title mb-6">{{$t('Product To Return')}}</p>
          </div>
          <div v-for="(item, index) in fulfillmentOrder.products" :key="index">
            <vx-card class="mt-4">
              <div class="grid w-full sm:grid-cols-4 grid-cols-1 sm:gap-4 gap-2">
                <div class="col-span-1">
                  <vs-input disabled :class="item.product.name ? 'input-customer-info-filled' : ''" class="w-full input-customer-info" 
                    v-model="item.product.name" :label-placeholder="$t('Product Name')" />
                </div>
                <div class="col-span-1">
                  <vs-input disabled :class="item.product.sku ? 'input-customer-info-filled' : ''" class="w-full input-customer-info" 
                    v-model="item.product.sku" :label-placeholder="$t('SKU')" />
                </div>
                <div class="sm:col-span-2 col-span-1">
                  <div class="flex items-end">
                    <div class="mr-6">
                      <vs-input disabled :class="item.fulfillment_center ? 'input-customer-info-filled remove-search' : ''" class="w-full input-customer-info"
                      v-model="item.fulfillment_center.name" :label-placeholder="$t('Fulfillment Center')" />
                    </div>
                    <div>
                      <p class="text-sm text-lightgray mb-1">{{ $t('Quantity') }}:</p>
                      <vs-input-number min="0" v-model="item.quantity" :max="fulfillmentOrdersQuantities[index]" value="0" name="quantity" class="stock-request-input-number"/>
                    </div>
                  </div>
                </div>
              </div>
            </vx-card>
          </div>
          <div class="mt-3 text-center" v-if="fulfillmentOrder.products.filter(element => element.quantity > 0).length === 0">
            <span class="text-danger text-sm">{{ $t('Should return at least one product') }}</span>
          </div>
        </div>
        <div v-else>
          <div class="text-center">
            <p class="font-medium leading-5 text-base sub-title mb-6">{{$t('Package To Return')}}</p>
          </div>
          <div v-for="(item, index) in returnPackagesData.packages" :key="index">
            <div class="flex items-center mt-4">
              <input class="package-checkbox" v-model="selectedReturns" type="checkbox" :id="`package-${index}`" :value="item"/>
              <label :for="`package-${index}`" class="package-label flex justify-between w-full ml-3">
                <div class="small-card py-2 px-3 flex items-center">
                  <img src="../../../assets/images/pages/Mask-group.svg" :style="changeSize(item.package.package_size.short_code? item.package.package_size.short_code.split('-FLY')[0] : '')"/>
                  <span class="leading-tight whitespace-nowrap font-medium text-primary ml-1">{{ item.package.package_size.short_code ? item.package.package_size.short_code.split('-FLY')[0] + '-Flyer' : '' }}</span>
                </div>
                <div :class="item.package.fragile ? 'fragile-checked' : ''" class="flex h-11 fragile">
                  <span class="px-3 rounded flex items-center">
                    <p class="text-sm mr-1">fragile</p>
                    <img src="../../../assets/images/pages/fragile.png" class="w-4 h-5"/>
                  </span>
                </div>
              </label>
            </div>
            <textarea v-validate="'required'" name="description" disabled class="resize-none w-full ml-6 mt-4 description " :placeholder="$t('Description1')" v-model="item.package.description" rows="2"/>
            <span class="text-danger text-sm ml-6" v-show="errors.has('description')">{{ errors.first('description') }}</span>
          </div>
          <p class="text-danger sm:text-left text-center text-sm mt-2" v-if="selectedReturns.length === 0 && clicks > 0"> {{ $t('This field is required') }}</p>
        </div>
        <div class="sm:flex items-center justify-between mt-6">
          <div class="flex items-center sm:justify-start justify-center">
            <p class="leading-tight text-darkgray font-medium">{{ $t('Cash Refund') }}</p>
          </div>
          <div class="sm:m-0 sm:text-left text-center sm:mt-0 mt-2">
            <input class="w-68" :class="returnPackagesData.refund_amount === '' ? 'input-off' : 'input-on'" type="text" :placeholder="$t('EGP')" name="refund amount" v-model="returnPackagesData.refund_amount">
            <p class="text-danger text-sm" v-show="errors.has('refund amount')">{{ errors.first('refund amount') }}</p>
          </div>
        </div>
        <div class="mt-6" v-if="title === 'Return Packages'">
          <div class="flex items-center sm:justify-start justify-center">
            <p class="leading-tight text-darkgray font-medium">{{ $t('Do you want to leave notes?') }}</p>
          </div>
          <textarea v-validate="'required'" name="notes" class="resize-none w-full mt-3 notes" :placeholder="$t('your notes..')" rows="5" v-model="returnPackagesData.order_notes"/>
          <p class="text-danger text-sm" v-show="errors.has('notes')">{{ errors.first('notes') }}</p>
          <div class="flex justify-end">
            <li style="color:#FF7556;"></li>
            <span class="text-sm text-icon">{{ $t('"Notes" will be printed on the order\'s label.') }}</span>
          </div>
        </div>
        <div v-if="title === 'Exchange Packages'">
          <div v-if="returnPackagesData.fulfillment_provider_metadata">
            <div class="text-center mt-10">
              <p class="font-medium leading-5 text-base sub-title mb-6">{{$t('Product To Pickup')}}</p>
            </div>
            <div v-for="(item, index) in productsToPick" :key="index">
              <vx-card class="mt-4 relative">
                <div class="grid w-full sm:grid-cols-2 grid-cols-1 sm:gap-4 gap-2">
                  <div class="col-span-1">
                    <v-select :class="item.fulfillment_center ? 'input-customer-info-filled remove-search' : ''" class="w-full input-customer-info"
                      autocomplete="nofill" label="name" :name="`fulfillment center-${index}`" v-model="item.fulfillment_center" v-validate="'required'"
                      :placeholder="$t('Fulfillment Center') + '*'" :options="fulfillmentCenters"/>
                    <span class="text-danger text-sm" v-show="errors.has(`fulfillment center-${index}`)">{{ errors.first(`fulfillment center-${index}`) ? errors.first(`fulfillment center-${index}`).split(`-${index}`)[0] + errors.first(`fulfillment center-${index}`).split(`-${index}`)[1]  : '' }}</span>
                  </div>
                  <div class="col-span-1">
                    <shipblu-v-select :class="item.product ? 'input-customer-info-filled remove-search' : ''" class="w-full input-customer-info product-dropdown" 
                      autocomplete="nofill" label="sku" optionalLabel="optionalLabel" :name="`product-${index}`" v-model="item.product" v-validate="'required'" 
                      :placeholder="$t('Product') + '*'" :options="productCatalog" @search="handleProductSearch" @keyup.enter.native="handleProductSearch" search/>
                    <span class="text-danger text-sm" v-show="errors.has(`product-${index}`)">{{ errors.first(`product-${index}`) ? errors.first(`product-${index}`).split(`-${index}`)[0] + errors.first(`product-${index}`).split(`-${index}`)[1]  : '' }}</span>
                  </div>
                  <div class="col-span-1">
                    <v-select :class="item.flags.length !== 0 ? 'input-customer-info-filled' : ''" class="w-full input-customer-info" 
                      multiple autocomplete="nofill" label="name" name="flags" v-model="item.flags" :placeholder="$t('Flags')" :options="productFlags"/>
                  </div>
                  <div class="col-span-1 flex items-end">
                    <div class="flex items-center">
                      <p class="text-sm text-lightgray">{{ $t('Quantity') }}:</p>
                      <div class="ml-5">
                        <vs-input-number min="1" :max="item.product ? item.product.current_inventory_level : 1" v-validate="'required|numeric'" name="quantity" v-model="item.quantity" :value="0" class="stock-request-input-number flex justify-start"/>
                        <span class="text-danger text-sm" v-show="errors.has('quantity')">{{ errors.first('quantity') }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="productsToPick.length > 1" @click="removeProduct(index)" class="remove-package-icon cursor-pointer remove-icon-position">
                  <feather-icon icon="XIcon" svgClasses="w-3 h-3"/>
                </div>
              </vx-card>
            </div>
            <div class="relative flex items-center justify-end mt-8">
              <div class="absolute add-products">
                <span class="text-sm font-medium py-1 px-2 rounded tooltip bg-white text-primary">{{$t('Add more products')}}</span>
              </div>
              <div class="p-1 flex self-center bg-primary rounded">
                <feather-icon @click="addProduct()" class="cursor-pointer" icon="PlusIcon" svgClasses="w-5 h-5 text-white" />
              </div>
            </div>
          </div>
          <div v-else>
            <div class="text-center mt-10">
              <p class="font-medium leading-5 text-base sub-title mb-6">{{$t('Package To Pickup')}}</p>
            </div>
            <div class="vx-row flex justify-between mt-6">
              <div class="vx-col flex items-center sm:m-0 m-auto">
                <p class="leading-tight text-darkgray font-medium">{{ $t('Packages') }}</p>
              </div>
            </div>
            <div v-if="packageSizes">
              <div class="grid md:grid-cols-4 grid-cols-2 gap-4 items-end mt-3">
                <div v-for="(item, index) in packageSizes" :key="index">
                  <div class="supply-table-cell-content flex items-center justify-between col-span-1" v-if="item.short_code != 'OSZ'">
                    <div class="flex items-center">
                      <img src="../../../assets/images/pages/Mask-group.svg" :style="changeSize(item.short_code.split('-FLY')[0])"/>
                      <span class="leading-tight text-lightgray whitespace-nowrap ml-1">{{ item.short_code.split('-FLY')[0] + '-Flyer' }}</span>
                    </div>
                    <div v-if="packages.length < 4" class="p-1 flex self-center bg-primary rounded">
                      <feather-icon @click="addMorePackage(item)" class="cursor-pointer" icon="PlusIcon" svgClasses="w-4 h-4 text-white" />
                    </div>
                  </div>
                </div>
              </div>
              <p class="text-danger sm:text-left text-center text-sm mt-2" v-if="packageSizes.length > 0 && packages.length === 0 && clicks > 0"> {{ $t('This field is required') }}</p>
            </div>
            <div v-if="packages" class="w-full mt-4">
              <div v-for="(item, index) in packages" :key="index">
                <div class="flex items-center mt-4">
                  <div class="small-card py-2 px-3 flex items-center">
                    <img src="../../../assets/images/pages/Mask-group.svg" :style="changeSize(item.package_size.short_code? item.package_size.short_code.split('-FLY')[0] : '')"/>
                    <span class="leading-tight whitespace-nowrap font-medium text-primary ml-1">{{ item.package_size.short_code ? item.package_size.short_code.split('-FLY')[0] + '-Flyer' : '' }}</span>
                  </div>
                  <textarea class="resize-none w-full mx-3 description sm:visible invisible" :placeholder="$t('Add Description')" v-model="item.description" rows="2"/>
                  <label class="prompt cursor-pointer">
                    <input v-model="item.fragile" :class="item.fragile ? 'checked' : ''" :value="item.fragile" type="checkbox" />
                    <span class="py-2 px-3 rounded flex items-center">
                      <p class="text-sm mr-1">fragile</p>
                      <img src="../../../assets/images/pages/fragile.png" class="w-4 h-5"/>
                    </span>
                  </label>
                  <div class="remove-package-icon ml-3 cursor-pointer">
                    <feather-icon @click="removePackage(index)" icon="XIcon" svgClasses="w-3 h-3"/>
                  </div>
                </div>
                <textarea class="resize-none w-full mt-3 description sm:hidden" :placeholder="$t('Add Description')" v-model="item.description" rows="2"/>
              </div>
            </div>
          </div>
          <div class="sm:flex justify-between mt-6">
            <div class="flex items-center justify-center">
              <p class="leading-tight text-darkgray font-medium">{{ $t('The parcel') }}</p>
            </div>
            <div class="flex items-center justify-center sm:mt-0 mt-2">
              <div v-for="(parcel, index) in parcels" :key="index">
                <label @click="checkedItem(parcel, parcels)" class="prompt cursor-pointer" :class="index === 0 ? 'mr-4' : ''">
                  <input v-model="returnPackagesData.is_customer_allowed_to_open_packages" :class="parcel.checked ? 'checked' : ''" :value="parcel.checked" type="checkbox" />
                  <span class="py-2 px-4 rounded text-sm">{{ parcel.name }}</span>
                </label>
              </div>
            </div>
          </div>
          <div v-if="parcels[1].checked  && financialsInsurance.insurance_package.id && financialsInsurance.insurance_package.id !== 1" class="flex items-center sm:text-left text-center sm:w-3/5 sm:px-2">
            <li class="w-2 mr-2" style="color:#FF7556;"></li>
            <img width="18px" height="18px" src="@/assets/images/pages/insurance-parcel.png" alt="">
            <span class="text-sm text-icon ml-2">{{$t('Insurance will not be applicable on parcels allowed to be open')}}</span>
          </div>
          <div class="sm:flex justify-between mt-6">
            <div class="flex items-center justify-center">
              <p class="leading-tight text-darkgray font-medium">{{ $t('Service Level') }}</p>
            </div>
            <div class="flex items-center justify-center sm:mt-0 mt-2">
              <div v-for="(serviceLevel, index) in serviceLevels" :key="index">
                <label @click="checkedItem(serviceLevel, serviceLevels)" class="prompt cursor-pointer" :class="index === 0 ? 'mr-4' : ''">
                  <input v-model="returnPackagesData.service_level" :class="returnPackagesData.service_level === serviceLevel ? 'checked' : ''" :value="serviceLevel" type="radio" />
                  <span class="py-2 px-4 rounded text-sm">{{ serviceLevel.name }}</span>
                </label>
              </div>
            </div>
          </div>
          <div class="sm:flex items-center justify-between mt-6">
            <div class="flex items-center sm:justify-start justify-center">
              <p class="leading-tight text-darkgray font-medium">{{ $t('Cash On Delivery (COD)') }}</p>
            </div>
            <div class="sm:m-0 sm:text-left text-center sm:mt-0 mt-2">
              <input class="w-68" :class="returnPackagesData.cash_amount === '' ? 'input-off' : 'input-on'" type="text" v-validate="'required'" :placeholder="$t('EGP')" name="cash amount" v-model="returnPackagesData.cash_amount">
              <p class="text-danger text-sm" v-show="errors.has('cash amount')">{{ errors.first('cash amount') }}</p>
            </div>
          </div>
          <div class="sm:flex items-start justify-between mt-6" v-if="!parcels[1].checked && financialsInsurance.insurance_package.id && financialsInsurance.insurance_package.id !== 1">
            <div>
              <div class="flex items-center sm:justify-start justify-center">
                <feather-icon icon="MinusIcon" svgClasses="w-4 mr-2 text-primary" />
                <p class="leading-tight text-darkgray font-medium">{{ $t('Products declared value') }}</p>
              </div>
              <div class="flex sm:items-center sm:text-left text-center m-auto sm:w-76 sm:px-2">
                <li class="w-2" style="color:#FF7556;"></li>
                <span class="text-sm text-icon ml-2">{{$t('Insurance fees will be applied on this amount in case the COD amount = 0')}}</span><br>
              </div>
              <div class="flex sm:items-center sm:text-left text-center m-auto sm:px-2">
                <span class="text-sm text-icon ml-2">({{$t('This amount will not be shown to your customer')}})</span>
              </div>
            </div>
            <div class="sm:m-0 sm:text-left text-center sm:mt-0 mt-2">
              <input class="w-68 text-right input-on" type="text" :placeholder="'0.00 ' + $t('EGP')" name="amount" v-model.number="declaredValue" style="background: #F1F3F6 !important;">
            </div>
          </div>
          <div class="sm:flex items-center justify-between mt-6">
            <div class="flex items-center sm:justify-start justify-center">
              <p class="leading-tight text-darkgray font-medium">{{ $t('Reference Number') }}</p>
            </div>
            <div class="sm:m-0 sm:text-left text-center sm:mt-0 mt-2">
              <input autocomplete="nofill" class="w-68" :class="returnPackagesData.merchant_order_reference === '' ? 'input-off' : 'input-on'" type="text" v-validate="'max:30'" :placeholder="$t('Add your order reference No.')" name="reference" v-model="returnPackagesData.merchant_order_reference">
              <p class="text-danger text-sm" v-show="errors.has('reference')">{{ errors.first('reference') }}</p>
            </div>
          </div>
          <div class="mt-6">
            <div class="flex items-center sm:justify-start justify-center">
              <p class="leading-tight text-darkgray font-medium">{{ $t('Do you want to leave notes?') }}</p>
            </div>
            <textarea class="resize-none w-full mt-3 notes" :placeholder="$t('your notes..')" rows="5" v-model="returnPackagesData.order_notes"/>
            <div class="flex justify-end">
              <li style="color:#FF7556;"></li>
              <span class="text-sm text-icon">{{ $t('"Notes" will be printed on the order\'s label.') }}</span>
            </div>
          </div>
        </div>
        <div v-if="orderPrice.subtotal" class="estimated-price mt-8">
          <p class="text-blue-900 font-medium">{{ $t('Estimated Price')}}</p>
          <div class="mt-3 flex justify-between text-sm text-lightgray">
            <p>{{ $t('Package base price')}}</p>
            <p class="font-medium">{{ Number(orderPrice.subtotal).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</p>
          </div>
          <div class="mt-2 flex justify-between text-sm text-lightgray">
            <p>{{ $t('Vat (14%)')}}</p>
            <p class="font-medium">{{ Number(orderPrice.vat).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</p>
          </div>
          <div class="mt-3 flex justify-between text-sm text-lightgray">
            <p>{{ $t('Insurance Fees')}}</p>
            <p class="font-medium">{{ Number(orderPrice.insurance_fees).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</p>
          </div>
          <div class="mt-2 flex justify-between text-sm">
            <p class="text-blue-900 font-medium">{{ $t('Total')}}</p>
            <p class="font-semibold text-primary">{{ Number(orderPrice.total).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</p>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-4">
        <button @click="closeModal" class="font-semibold btn disable-btn">{{ $t('Cancel') }}</button>
        <button v-if="title === 'Exchange Packages'" @click="addExchange" class="font-semibold btn active-btn">{{ $t('Exchange') }}</button>
        <button v-else @click="addReturn" class="font-semibold btn active-btn">{{ $t('Add Return Order') }}</button>
      </div>
    </shipblu-prompt>
  </div>
</template>

<script>
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'
import {sendRequest, sendFulfillmentRequest} from '../../../http/axios/requestHelper.js'
import vSelect from 'vue-select'
import i18nData from '../../../i18n/i18nData.js'
import common from '../../../assets/utils/common'
import ShipbluVSelect from 'shipblu-vue-select'

export default {
  props: ['title', 'returnPackagesFlag', 'governorateId', 'merchant', 'returnPackagesData', 'userMerchant'],
  data () {
    return {
      releaseVersion: process.env.VUE_APP_RELEASE_VERSION,
      parcels: [
        {
          name: 'Can’t Open',
          checked: true
        },
        {
          name: 'Can Open',
          checked: false
        }
      ],
      financialsInsurance:{
        insurance_package: {}
      },
      packageSizes: [],
      serviceLevels: [],
      selectedReturns: [],
      packages: [],
      clicks: 0,
      orderPrice: {},
      fulfillmentCenter: '',
      fulfillmentCenters: [],
      allProductsList: [],
      productCatalog: [],
      productFlags: [],
      searchVal: '',
      searchInProgress: false,
      searchedValue: ' ',
      fulfillmentOrder: {
        products: []
      },
      fulfillmentOrdersQuantities: [],
      productsToPick: []
    }
  },
  computed: {
    declaredValue: {
      get () {
        return this.returnPackagesData.declared_value ? this.returnPackagesData.declared_value.toFixed(2) : '0.00'
      },
      set (newDeclaredValue) {
        this.returnPackagesData.declared_value = Number(newDeclaredValue)
      }
    }
  },
  components: {
    ShipbluPrompt,
    vSelect,
    ShipbluVSelect
  },
  watch: {
    'productsToPick': {
      handler (val) {
        const choosedProducts = []
        this.productCatalog = []
        this.allProductsList.forEach(element => {
          this.productCatalog.push(element)
        })
        val.forEach(item => {
          if (item.product && item.product !== null) {
            choosedProducts.push(item.product)
            item.quantity = (item.quantity > item.product.current_inventory_level) ? item.product.current_inventory_level : item.quantity
          }
        })
        for (let i = 0; i < choosedProducts.length; i++) {
          this.productCatalog.forEach(element => {
            if (element === choosedProducts[i]) {
              this.productCatalog.splice(this.productCatalog.indexOf(element), 1)
            }
          })
        }
      },
      deep: true
    },
    'serviceLevels' () {
      this.returnPackagesData.service_level = this.serviceLevels[0]
    },
    returnPackagesFlag () {
      if (this.returnPackagesFlag) {
        if (this.releaseVersion !== 'staging') {
          this.$mixpanel.track(this.title === 'Exchange Packages' ? 'New Exchange Request' : 'New Return', {
            merchant_name: this.merchant.name,
            merchant_email: this.userMerchant.email,
            source: /(android)/i.test(navigator.userAgent)
          })
        }
        this.loadServicesAvailability(this.governorateId)
        this.selectedReturns = []
        if (this.title === 'Exchange Packages' && this.returnPackagesData.fulfillment_provider_metadata) {
          this.productsToPick = [
            {
              fulfillment_center: '',
              product: '',
              flags: [],
              quantity: 1
            }
          ]
          this.loadProductCatalog()
          this.loadProductFlags()
          this.loadFulfillmentCenters()
        }
        this.loadFinancialsInsurance()
      }
    },
    'returnPackagesData' () {
      if (this.returnPackagesData.fulfillment_provider_metadata) {
        this.loadFulfillmentOrder()
      }
    },
    'selectedReturns' () {
      if (this.selectedReturns.length > 0 && this.title === 'Return Packages') {
        this.loadEstimatedPrice()
      } else if (this.canEstimatePriceExchange() && (this.title === 'Exchange Packages')) {
        this.loadEstimatedPrice()
      }
    },
    'returnPackagesData.service_level' () {
      if (this.canEstimatePriceExchange()) {
        this.loadEstimatedPrice()
      }
    },
    'returnPackagesData.cash_amount' () {
      if (this.canEstimatePriceExchange()) {
        this.loadEstimatedPrice()
      }
    },
    'declaredValue' () {
      if (this.canEstimatePriceExchange()) {
        this.loadEstimatedPrice()
      }
    },
    'packages' () {
      if (this.canEstimatePriceExchange()) {
        this.loadEstimatedPrice()
      }
    },
    'returnPackagesData.refund_amount' () {
      if (this.selectedReturns.length > 0 && this.title === 'Return Packages') {
        this.loadEstimatedPrice()
      } else if (this.canEstimatePriceExchange() && (this.title === 'Exchange Packages')) {
        this.loadEstimatedPrice()
      }
    },
    'searchVal' (val) {
      if (val === '') {
        this.loadProductCatalog()
      }
    }
  },
  methods: {
    loadFinancialsInsurance () {
      sendRequest(false, false, this, `api/v1/merchants/${this.merchant.id}/insurance-packages/`, 'get', null, true,
        (response) => {
          this.financialsInsurance = response.data
        }
      )
    },
    canEstimatePriceExchange () {
      if (this.selectedReturns.length > 0 && this.packages.length > 0) {
        return true
      }
    },
    loadEstimatedPrice () {
      let type = ''
      switch (this.title) {
      case 'Exchange Packages':
        type = 'exchange'
        break
      case 'Return Packages':
        type = 'return'
        break
      default:
        break
      }
      const orderData = {}
      orderData.packages = []
      if (type === 'return') {
        orderData.service_level = this.returnPackagesData.service_level.id
        orderData.packages = this.selectedReturns.map(item => item.package.package_size.id)
        orderData.to_governorate = this.returnPackagesData.customer.address.zone.city.governorate.id
        orderData.cash_amount = this.returnPackagesData.refund_amount ? Number(this.returnPackagesData.refund_amount) : 0
      } else {
        orderData.order = this.returnPackagesData.id
        orderData.service_level = this.returnPackagesData.service_level.id
        orderData.old_packages = this.selectedReturns.map(item => item.package.package_size.id)
        orderData.new_packages = this.packages.map(item => item.package_size.id)
        orderData.refund = Number(this.returnPackagesData.refund_amount) > 0
        orderData.refund_amount = this.returnPackagesData.refund_amount ? Number(this.returnPackagesData.refund_amount) : 0
        orderData.cash_amount = this.returnPackagesData.cash_amount ? Number(this.returnPackagesData.cash_amount) : 0
        orderData.declared_value = this.returnPackagesData.declared_value ? Number(this.returnPackagesData.declared_value) : 0
      }
      this.$vs.loading.close()
      sendRequest(false, false, this, `api/v1/pricing/orders/${type}/`, 'post', orderData, true,
        (response) => {
          this.orderPrice = response.data
        }
      )
    },
    checkedItem (element, array) {
      array.forEach(item => {
        if (item.name === element.name) {
          item.checked = true
        } else {
          item.checked = false
        }
      })
    },
    changeSize (shortCode) {
      switch (shortCode) {
      case 'S':
        return 'width: 20px;height: 25px'
      case 'M':
        return 'width: 25px; height: 30px;'
      case 'L':
        return 'width: 30px; height: 35px;'
      case 'XL':
        return 'width: 35px; height: 40px;'
      }
    },
    removePackage (index) {
      this.packages.splice(index, 1)
    },
    addMorePackage (item) {
      this.packages.push({
        package_size: item,
        description: '',
        fragile: false
      })
    },
    loadServicesAvailability (id) {
      sendRequest(false, false, this, `api/v1/services-availability/?governorate_id=${id}&subscription_id=${this.merchant.subscription.id}`, 'get', null, true, 
        (response) => {
          const packageSizes = response.data.map(element => element.package_size)
          const serviceLevels = response.data.map(element => element.service_level)
          const key = 'id'
          this.packageSizes = [...new Map(packageSizes.filter(item => item !== null).map(item => [item[key], item])).values()]
          this.serviceLevels = [...new Map(serviceLevels.map(item => [item[key], item])).values()]
          this.serviceLevels = this.serviceLevels.reverse()
        }
      )
    },
    addReturn () {
      this.clicks++
      this.$validator.validateAll().then(result => {
        if (result && (this.selectedReturns.length > 0 || (this.fulfillmentOrder.products && this.fulfillmentOrder.products.filter(element => element.quantity > 0).length > 0))) {
          const returnObj = {
            customer: {
              full_name: this.returnPackagesData.customer.full_name,
              phone: this.returnPackagesData.customer.phone,
              address: {
                line_1: this.returnPackagesData.customer.address.line_1,
                line_2: this.returnPackagesData.customer.address.line_2,
                zone: this.returnPackagesData.customer.address.zone.id
              }
            },
            packages: [],
            service_level: this.returnPackagesData.service_level.id,
            cash_amount: this.returnPackagesData.refund_amount ? Number(this.returnPackagesData.refund_amount) : 0,
            cash_processing: this.returnPackagesData.refund
          }
          if (this.fulfillmentOrder.products && this.fulfillmentOrder.products.filter(element => element.quantity > 0).length > 0) {
            returnObj.fulfillment = {
              products: []
            }
            returnObj.packages.push({
              package_size: 2,
              description: '',
              fragile: false
            })
            this.fulfillmentOrder.products.forEach(element => {
              if (element.quantity > 0) {
                returnObj.fulfillment.products.push({
                  product: element.product.sku,
                  quantity: Number(element.quantity),
                  fulfillment_center: element.fulfillment_center.id
                })
              }
            })
          }
          this.selectedReturns.forEach(element => {
            returnObj.packages.push({
              package_size: element.package.package_size.id,
              description: element.package.description,
              fragile: element.package.fragile
            })
          })
          if (this.returnPackagesData.customer.email !== null) {
            returnObj.customer.email = this.returnPackagesData.customer.email
          }
          if (this.returnPackagesData.order_notes !== null && this.returnPackagesData.order_notes !== '') {
            returnObj.order_notes = this.returnPackagesData.order_notes
          }
          if (!this.isOnline) this.$emit('returnPackagesFlag', false)
          sendRequest(false, false, this, 'api/v1/returns/?ref=webapp', 'post', returnObj, true,
            () => {
              if (this.releaseVersion !== 'staging') {
                this.$mixpanel.track('Complete New Return', {
                  merchant_name: this.merchant.name,
                  merchant_email: this.userMerchant.email,
                  source: /(android)/i.test(navigator.userAgent)
                })
              }
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text:i18nData[this.$i18n.locale]['Return'] + i18nData[this.$i18n.locale][' has been created successfully!'],
                position: 'top-center'
              })
              this.closeModal()
              this.$emit('returnPackagesFlag', false)
              this.sucessModal = true
            }
          )
        }
      })
    },
    addExchange () {
      this.clicks++
      if (this.returnPackagesData.fulfillment_provider_metadata) {
        this.$validator.validateAll().then(result => {
          if (result && ((this.packages.length > 0 && this.selectedReturns.length > 0) || (this.fulfillmentOrder.products && this.fulfillmentOrder.products.filter(element => element.quantity > 0).length > 0))) {
            const exchangeObj = {
              delivery :{
                service_level: this.returnPackagesData.service_level.id,
                new_packages: [],
                old_packages: [],
                order: this.returnPackagesData.id,
                refund: Number(this.returnPackagesData.refund_amount) > 0,
                refund_amount: this.returnPackagesData.refund_amount ? Number(this.returnPackagesData.refund_amount) : 0,
                cash_processing: this.returnPackagesData.cash_processing,
                is_customer_allowed_to_open_packages: this.returnPackagesData.is_customer_allowed_to_open_packages,
                cash_amount: this.returnPackagesData.cash_amount ? Number(this.returnPackagesData.cash_amount) : 0,
                declared_value: Number(this.declaredValue)
              },
              outbound_products: [],
              inbound_products: [],
              outbound_order: this.returnPackagesData.fulfillment_provider_metadata.shipblu.order
            }
            if (this.returnPackagesData.order_notes !== '' && this.returnPackagesData.order_notes !== null) exchangeObj.delivery.notes = this.returnPackagesData.order_notes
            if (this.returnPackagesData.merchant_order_reference !== '' && this.returnPackagesData.merchant_order_reference !== null) exchangeObj.delivery.merchant_order_reference = this.returnPackagesData.merchant_order_reference
            if (this.productsToPick && this.productsToPick.filter(element => element.quantity > 0).length > 0) {
              exchangeObj.delivery.old_packages.push({
                package_size: 2,
                description: '',
                fragile: false
              })
              exchangeObj.delivery.new_packages.push({
                package_size: 2,
                description: '',
                fragile: false
              })
              this.productsToPick.forEach(element => {
                if (element.quantity > 0) {
                  exchangeObj.outbound_products.push({
                    product: element.product.sku,
                    flags: element.flags ? element.flags.map(item => item.id) : [],
                    quantity: Number(element.quantity),
                    fulfillment_center: element.fulfillment_center.id
                  })
                }
              })
              this.fulfillmentOrder.products.forEach(element => {
                if (element.quantity > 0) {
                  exchangeObj.inbound_products.push({
                    product: element.product.sku,
                    flags: element.flags ? element.flags.map(item => item.id) : [],
                    quantity: Number(element.quantity),
                    fulfillment_center: element.fulfillment_center.id
                  })
                }
              })
            }
            this.selectedReturns.forEach(element => {
              exchangeObj.old_packages.push({
                package_size: element.package.package_size.id,
                description: element.package.description,
                fragile: element.package.fragile
              })
            })
            this.packages.forEach(element => {
              exchangeObj.new_packages.push({
                package_size: element.package_size.id,
                description: element.description,
                fragile: element.fragile
              })
            })
            if (!this.isOnline) this.$emit('returnPackagesFlag', false)
            sendFulfillmentRequest(false, false, this, 'api/v1/fc/orders/exchange/?ref=webapp', 'post', exchangeObj, true,
              () => {
                if (this.releaseVersion !== 'staging') {
                  this.$mixpanel.track('Complete New Exchange', {
                    merchant_name: this.merchant.name,
                    merchant_email: this.userMerchant.email,
                    source: /(android)/i.test(navigator.userAgent)
                  })
                }
                this.$vs.notify({
                  color:'success',
                  title:i18nData[this.$i18n.locale]['Success'],
                  text:i18nData[this.$i18n.locale]['Exchange'] + i18nData[this.$i18n.locale][' has been created successfully!'],
                  position: 'top-center'
                })
                this.closeModal()
                this.$emit('returnPackagesFlag', false)
                this.sucessModal = true
              }
            )
          }
        })
      } else {
        this.$validator.validateAll().then(result => {
          if (result && ((this.packages.length > 0 && this.selectedReturns.length > 0) || (this.fulfillmentOrder.products && this.fulfillmentOrder.products.filter(element => element.quantity > 0).length > 0))) {
            const exchangeObj = {
              service_level: this.returnPackagesData.service_level.id,
              new_packages: [],
              old_packages: [],
              order: this.returnPackagesData.id,
              refund: Number(this.returnPackagesData.refund_amount) > 0,
              refund_amount: this.returnPackagesData.refund_amount ? Number(this.returnPackagesData.refund_amount) : 0,
              cash_processing: this.returnPackagesData.cash_processing,
              is_customer_allowed_to_open_packages: this.returnPackagesData.is_customer_allowed_to_open_packages,
              cash_amount: this.returnPackagesData.cash_amount ? Number(this.returnPackagesData.cash_amount) : 0,
              declared_value: Number(this.declaredValue)
            }
            if (this.returnPackagesData.order_notes !== '' && this.returnPackagesData.order_notes !== null) exchangeObj.notes = this.returnPackagesData.order_notes
            if (this.returnPackagesData.merchant_order_reference !== '' && this.returnPackagesData.merchant_order_reference !== null) exchangeObj.merchant_order_reference = this.returnPackagesData.merchant_order_reference
            if (this.productsToPick && this.productsToPick.filter(element => element.quantity > 0).length > 0) {
              exchangeObj.fulfillment = {
                outbound_products: [],
                inbound_products: []
              }
              exchangeObj.old_packages.push({
                package_size: 2,
                description: '',
                fragile: false
              })
              exchangeObj.new_packages.push({
                package_size: 2,
                description: '',
                fragile: false
              })
              this.productsToPick.forEach(element => {
                if (element.quantity > 0) {
                  exchangeObj.fulfillment.outbound_products.push({
                    product: element.product.sku,
                    flags: element.flags ? element.flags.map(item => item.id) : [],
                    quantity: Number(element.quantity),
                    fulfillment_center: element.fulfillment_center.id
                  })
                }
              })
              this.fulfillmentOrder.products.forEach(element => {
                if (element.quantity > 0) {
                  exchangeObj.fulfillment.inbound_products.push({
                    product: element.product.sku,
                    flags: element.flags ? element.flags.map(item => item.id) : [],
                    quantity: Number(element.quantity),
                    fulfillment_center: element.fulfillment_center.id
                  })
                }
              })
            }
            this.selectedReturns.forEach(element => {
              exchangeObj.old_packages.push({
                package_size: element.package.package_size.id,
                description: element.package.description,
                fragile: element.package.fragile
              })
            })
            this.packages.forEach(element => {
              exchangeObj.new_packages.push({
                package_size: element.package_size.id,
                description: element.description,
                fragile: element.fragile
              })
            })
            if (!this.isOnline) this.$emit('returnPackagesFlag', false)
            sendRequest(false, false, this, 'api/v1/exchanges/?ref=webapp', 'post', exchangeObj, true,
              () => {
                if (this.releaseVersion !== 'staging') {
                  this.$mixpanel.track('Complete New Exchange', {
                    merchant_name: this.merchant.name,
                    merchant_email: this.userMerchant.email,
                    source: /(android)/i.test(navigator.userAgent)
                  })
                }
                this.$vs.notify({
                  color:'success',
                  title:i18nData[this.$i18n.locale]['Success'],
                  text:i18nData[this.$i18n.locale]['Exchange'] + i18nData[this.$i18n.locale][' has been created successfully!'],
                  position: 'top-center'
                })
                this.closeModal()
                this.$emit('returnPackagesFlag', false)
                this.sucessModal = true
              }
            )
          }
        })
      }
    },
    closeModal () {
      this.clicks = 0
      this.packages = []
      this.fulfillmentOrdersQuantities = []
      this.fulfillmentOrder = {
        products: []
      }
      this.orderPrice = {}
      this.$emit('returnPackagesFlag', false)
      this.$emit('loadShipments')
      this.$emit('selected', [])
    },
    loadFulfillmentCenters () {
      sendFulfillmentRequest(false, false, this, 'api/v1/fc/fulfillment-centers/', 'get', null, true,
        (response) => {
          this.fulfillmentCenters = response.data.results
        }
      )
    },
    loadFulfillmentOrder () {
      sendFulfillmentRequest(false, false, this, `api/v1/fc/orders/outbound/${this.returnPackagesData.fulfillment_provider_metadata.shipblu.order}/`, 'get', null, true,
        (response) => {
          this.fulfillmentOrder = response.data
          this.fulfillmentOrder.products.forEach(element => {
            this.fulfillmentOrdersQuantities.push(element.quantity)
          })
        }, () => {}
      )
    },
    handleProductSearch (search) {
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadProductsSearch, 1000)
    },
    loadProductsSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      if (this.searchVal) {
        this.loadProductCatalog()
      }
    },
    loadProductCatalog () {
      this.searchInProgress = true
      sendFulfillmentRequest(false, false, this, `api/v1/fc/products/?merchant=${this.merchant.id}&limit=1000&current_inventory_level__gt=0&search=${this.searchVal}`, 'get', null, true,
        (response) => {
          this.allProductsList = response.data.results
          this.productCatalog = response.data.results
          this.allProductsList.map(item => { item.optionalLabel = `Name: ${item.name} . ${item.current_inventory_level} available` })
          this.searchedValue = this.searchVal
        }
      )
      this.searchInProgress = false
    },
    loadProductFlags () {
      sendFulfillmentRequest(false, false, this, 'api/v1/fc/flags/', 'get', null, true,
        (response) => {
          this.productFlags = response.data.results
        }
      )
    },
    addProduct () {
      this.productsToPick.push({
        fulfillment_center: '',
        product: '',
        flags: [],
        quantity: 1
      })
    },
    removeProduct (index) {
      this.productsToPick.splice(index, 1)
    }
  }
}
</script>

<style lang='scss'>
.exchange-title {
  color: #475C6F !important;
}
.sub-title {
  color: #0A3266 !important;
}

.text-darkgray {
  color: #475C6F;
}
.supply-table-cell-content {
  align-items: center;
  padding: 8px;
  background: #EBF5FF;
  border-radius: 4px;
}
.text-lightgray {
  color: #6C84A3;
}
.small-card {
  background: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(108, 132, 163, 0.32);
  border-radius: 4px;
  text-align: center;
}
textarea.description {
  height: 39px;
  border: none;
  padding: 4px 6px;
  background: rgba(206, 214, 224, 0.24);
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  color: #6C84A3;
  &::placeholder {
    color: #9DADC2;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
}
textarea.notes {
  border: none;
  background: #FFFFFF;
  padding: 12px;
  box-shadow: 0px 2px 10px rgba(108, 132, 163, 0.32);
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  color: #6C84A3;
  &::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: rgba(157, 173, 194, 0.6);
  }
}
.remove-package-icon {
  background: rgba(157, 173, 194, 0.32);
  border-radius: 3px;
  padding: 0px 4px;
  &:hover {
    background: #9DADC2;
    color: #fff;
  }
}
input.input-off {
  border: none;
  padding: 8px;
  background: #F1F3F6;
  border-radius: 4px;
  &::placeholder {
    color: #6C84A3;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
}
input.input-on {
  border: none;
  padding: 8px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(108, 132, 163, 0.28);
  border-radius: 4px;
  color: #1C5BFE;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}
.pointer-events-none {
  pointer-events: none;
}
.checked + span{
  background: #FFFFFF;
  border: 0.6px solid #1C5BFE;
  box-shadow: 0px 2px 10px rgba(108, 132, 163, 0.28);
  border-radius: 4px;
}
.fragile-checked{
  color: #1C5BFE !important;
  background: #FFFFFF;
  border: 0.6px solid #1C5BFE;
  box-shadow: 0px 2px 10px rgba(108, 132, 163, 0.28);
  border-radius: 4px;
}
.active-btn {
  color: #fff;
  font-weight: 500;
  background: #1C5BFE;
  box-shadow: 0px 2px 8px rgba(10, 50, 102, 0.32);
}
.disable-btn {
  background: #F1F3F6;
  color: #6C84A3;
}
input[type=checkbox]:checked + label.package-label{
  background: #FFFFFF;
  border: unset !important;
  box-shadow: unset !important;
  border-radius: 4px;
}
input[type=checkbox]:checked + label.package-label span {
  background: white !important;
}
label.package-label span {
  background: white !important;
  border-radius: 4px;
  border: unset !important;
  &:hover {
    box-shadow: unset !important;
  }
}
.package-checkbox {
  width: 20px !important;
  height: 20px !important;
}
.fragile span{
  background: #F1F3F6 !important;
  border-radius: 4px;
}
</style>