a<template>
    <div v-on:click.stop="clicktr" v-if="$parent.multiple">
      <vs-checkbox
        v-on:click.stop
        v-if="$parent.multiple"
        :checked="isSelected"
        size="small"
        @change="handleCheckbox"
      />
    </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'VsTr',
  props:{
    data: {
      default: null
    },
  },
  computed:{
    isSelected(){ 
      if(this.$parent.multiple && this.$parent.value) {
        return this.data ? this.$parent.value.some(
              (item) => JSON.stringify(item) === JSON.stringify(this.data)
            ) : false
      } else {
        return this.data ? this.$parent.value == this.data : false
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.colspan = this.$parent.$refs.thead.querySelectorAll('th').length
      if (this.$slots.expand) {
        this.colspan++
      }
    })
  },
  created() {
    if(this.$slots.expand) this.$parent.hasExpadableData = true
    this.browserName = navigator.userAgent
  },
  methods:{
    handleCheckbox() {
      this.$parent.handleCheckbox(this.data)
    },
    clicktr (evt) {
      this.$parent.clicktr(this.data, true)
    },
  }
}
</script>